<template>
  <div class="upWrapper">
    <el-dialog :visible.sync="dialogVisible">
      <el-form
        label-position="right"
        :rules="rules"
        ref="form"
        label-width="100px"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="城市" prop="areaId">
          <el-select
            v-model.trim="formInline.areaId"
            @change="areaChange"
            filterable
            placeholder="请选择"
          >
            <el-option label="全部" value></el-option>
            <el-option
              :label="area.areaName"
              :value="area.areaId"
              :key="area.areaId"
              v-for="area in areaList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item ('searchModule.region')" prop="streetId">
          <el-select
            v-model.trim="formInline.streetId"
            @change="streetChange"
            filterable
            placeholder="请选择"
          >
            <el-option label="全部" value></el-option>
            <el-option
              :label="area.areaName"
              :value="area.areaId"
              :key="area.areaId"
              v-for="area in streetList"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择车场" prop="parkList">
          <el-transfer
            v-model="formInline.parkList"
            :props="{
              key: 'parkId',
              label: 'parkName',
            }"
            :data="data"
            :titles="['未选择', '已选择']"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save" :loading="loading">确定</el-button>
        <el-button @click="resetForm">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        areaId: "",
        streetId: "",
        parkList: [],
      },
      areaList: [],
      streetList: [],
      data: [],
      loading: false,
      dialogVisible: false,
      rules: {
        // areaId: [{
        //   required: true,
        //   message: '请输入车场',
        //   trigger: 'blur'
        // }],
        // streetId: [{
        //   required: true,
        //   message: '请选择区域',
        //   trigger: 'change'
        // }],
        parkList: [
          {
            required: true,
            message: "请选择车场",
            trigger: "change",
          },
        ],
      },
      areaId: "",
    };
  },
  methods: {
    init() {
      this.dialogVisible = true;
    },
    resetForm() {
      this.formInline = {
        areaId: "",
        streetId: "",
        parkList: [],
      };
      this.dialogVisible = false;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let url = "/acb/2.0/bacb/upgrade/startUpgrade";
          this.$axios
            .post(url, {
              data: {
                parkIds: this.formInline.parkList.join(),
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$alert("升级开始，请稍后", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
                this.dialogVisible = false;
                this.$emit("searchData");
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      });
    },
    areaChange(areaId) {
      this.streetList = [];
      this.formInline.streetId = "";
      this.areaId = areaId;
      if (!areaId) return;
      this.getPark(areaId);
      this.getStreet(areaId);
    },
    streetChange(streetId) {
      if (!streetId) {
        this.getPark(this.areaId);
        return;
      }
      this.getPark(streetId);
    },
    getAreaList() {
      let url = "/acb/2.0/systems/loginUser/initAreaList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          if (res.value.depth == 3) {
            this.areaList = res.value.areaList;
          } else {
            this.streetList = res.value.areaList;
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getStreet(areaId) {
      let url = "/acb/2.0/systems/loginUser/getChildrenArea";
      this.$axios
        .get(url, {
          data: {
            areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.streetList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getPark(areaId) {
      this.area = areaId;
      let url = "/acb/2.0/bacb/park/list";
      this.$axios
        .get(url, {
          data: {
            page: 0,
            pageSize: 0,
            areaId: areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.data = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  mounted() {
    this.getAreaList();
  },
};
</script>

<style></style>
