<template>
  <div class="fileWrapper">
    <el-dialog title="添加包" :visible.sync="dialogVisible" width="60%">
      <el-upload class="upload-demo" ref="upload" action="/acb/2.0/bacb/upgrade/uploadFile"
        :headers='uploadData' name='image' :on-remove="handleRemove" :on-success='success'
        :on-error='error' :on-progress='progress' :before-upload='beforeUpload'
        :file-list="fileList" :auto-upload="false" :limit="1">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">
          上传到服务器</el-button>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        fileList: [],
        dialogVisible: false,
        uploadData: {
          'Session-Id': sessionStorage.token
        }
      }
    },
    methods: {
      init() {
        this.fileList = []
        this.dialogVisible = true
      },
      handleRemove(file, fileList) {
        // console.log(file, fileList);
      },
      beforeUpload(file) {
        // console.log(file)
      },
      success(res, file, fileList) {
        // console.log(res)
        if (res.state == 0) {
          this.dialogVisible = false
          this.$emit('searchData')
        } else {
          this.fileList = []
        }
        this.$alert(res.desc, this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      },
      error(err, file, fileList) {
        // console.log(err)
      },
      progress(event, file, fileList) {
        // console.log(event)
        // console.log(file)
        // console.log(fileList)
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${file.name}？`);
      },
      submitUpload() {
        this.$refs.upload.submit();
      },
    }
  }

</script>

<style>

</style>
