<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <el-form-item :label="$t('searchModule.park_name')">
            <el-autocomplete
              class="inline-input"
              v-model="parkId"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入内容"
              value-key="parkName"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item>
            <!-- v-if="$route.meta.authority.button.query" -->
            <el-button
              type="primary"
              style="margin-right: 5px"
              icon="el-icon-search"
              @click="
                pageNum = 1;
                searchData();
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <!-- v-if="$route.meta.authority.button.add" -->
            <el-button type="primary" icon="el-icon-plus" @click="up">升级 </el-button>
            <!-- v-if="$route.meta.authority.button.evaluate" -->
            <el-button type="primary" icon="el-icon-document-copy" plain @click="update"
              >上传包
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <upGrade ref="up" @searchData="searchData"></upGrade>
    <fileSelect ref="file" @searchData="searchData"></fileSelect>
  </div>
</template>

<script>
import upGrade from "./up.vue";
import fileSelect from "./file.vue";
export default {
  components: {
    upGrade,
    fileSelect,
  },
  watch: {
    parkId(ne) {
      if (ne == "") {
        this.formInline.parkId = "";
      }
    },
  },
  data() {
    return {
      parkId: "",
      formInline: {
        parkId: "",
        parkName: "",
      },
      pageNum: 1,
      pageSize: 15,
      total: 0,
      loading: false,
      tableData: [],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "currentFileName",
          label: "当前版本",
          width: "",
        },
        {
          prop: "updatedTime",
          label: "最后更新时间",
          width: "",
        },
        {
          prop: "historyFileName",
          label: "上个版本",
          width: "",
        },
        {
          prop: "status",
          label: "最近升级状态",
          width: "",
          formatter: (row, column, cellValue, index) => {
            let obj = {
              0: "升级中",
              1: "升级成功",
              2: "升级失败",
            };
            return obj[cellValue];
          },
        },
        {
          prop: "upgradeDesc",
          label: "升级描述",
          width: "",
        },
      ],
    };
  },
  methods: {
    searchData() {
      let url = "/acb/2.0/bacb/upgrade/pageUpgradeLog";
      this.$axios
        .get(url, {
          data: {
            pageNum: this.pageNum,
            pageSize: 15,
            parkId: this.formInline.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    up() {
      let url = "/acb/2.0/bacb/upgrade/queryCurrentFile";
      this.$axios
        .get(url)
        .then((res) => {
          // console.log(res)
          if (res.state == 0) {
            this.$refs.up.init();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((err) => {
          // console.log(err)
        });
    },
    update() {
      this.$refs.file.init();
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
  },
  mounted() {
    this.searchData();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;
  }
}
</style>
